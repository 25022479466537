'use client'
import Title from '@/components/atoms/Title'
import LoginForm from '@/components/organisms/web-forms/session-management/LoginForm'
import LogoImage from '@/public/assets/images/logo/logo-globallynx.png'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'

export default function SignIn() {
  const router = useRouter()

  const { data: session } = useSession()
  if (session) {
    router.push('/')
    return null
  }
  return (
    <div className="flex flex-col items-center justify-center bg-white pt-8 pb-4 rounded-lg shadow-lg">
      <Image src={LogoImage} alt="Mountains" width={150} height={60} className="py-5" />
      <Title className="text-blue-darkest text-smallTitle">Welcome</Title>
      <LoginForm className="max-w-md" />
    </div>
  )
}
